(function () {
  angular
    .module('kmi.lms.ubt.user.edit', ['kmi.lms.user.common', 'kmi.lms.core', 'kmi.lms.user.components'])
    .config(function ($stateProvider) {
      $stateProvider
        .state('prompt.userRegistration', {
          url: '/register',
          component: 'ubtCreateAccount',
          data: {
            label: 'Registration',
            availableFor: ['anonymous'],
          },
          resolve: {
            user: function (User) {
              return User.newUser({
                country: 'US',
                timeZoneId: 86,
              });
            },
          },
        })
        .state('edit.account', {
          url: '/account?activeView',
          component: 'ubtEditAccount',
          params: {
            activeView: null,
          },
          data: {
            label: 'Account',
            availableFor: ['regularUser', 'admin'],
          },
          resolve: {
            user: function (User) {
              return User.get();
            },
          },
        });
    });
})();
