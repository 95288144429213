(function () {
  angular.module('kmi.lms.ubt.user.edit').component('ubtRequiredUserDataComponent', {
    template: require('ajs/custom_modules/ubt/user/edit/components/required-user-data-modal.html').default,
    controllerAs: 'vm',
    controller: UbtRequiredUserDataController,
    bindings: {
      modalInstance: '<',
    },
  });

  /* @ngInject */
  function UbtRequiredUserDataController($state) {
    var vm = this;

    vm.closeModal = closeModal;
    vm.state = $state;

    function closeModal() {
      vm.modalInstance.close();
    }
  }
})();
