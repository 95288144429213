(function () {
  angular.module('kmi.lms.ubt').component('ubtContacts', {
    template: require('ajs/custom_modules/ubt/contact/contact.html').default,
    controller: UbtContactsController,
    controllerAs: 'vm',
  });

  function UbtContactsController(globalConfig) {
    var vm = this;

    vm.contacts = globalConfig.contacts;
  }
})();
