(function () {
  function registeredCourses(
    $transition$,
    userCoursesService,
    LearningObjectRegistration,
    serverErrorHandlerService,
    _,
  ) {
    return userCoursesService.getCompilations($transition$.params().id).then(function (courses) {
      var compilationCourses = _.uniq(
        _.map(courses, function (course) {
          return {
            id: course.id,
            name: course.name,
            selected: course.lastAccessed,
          };
        }),
        'id',
      );

      var course = {
        stat: null,
        selectedCourse: null,
        sessions: null,
        compilationStructure: null,
      };

      if (compilationCourses && compilationCourses.length) {
        var selectedCourse = _.find(compilationCourses, 'selected');
        course.selectedCourse = selectedCourse || compilationCourses[0];
      } else {
        course.sessions = [];
      }

      function getStat() {
        return userCoursesService.getCompilationModulesStat(course.selectedCourse.id, $transition$.params().id).then(
          function (stat) {
            course.stat = stat;
          },
          function (reason) {
            return serverErrorHandlerService.handleForbiddenError(reason);
          },
        );
      }

      return {
        courses: compilationCourses,
        course: course,
        getSessions: function () {
          course.sessions = null;

          return userCoursesService
            .getScormSessions(course.selectedCourse.id, $transition$.params().id)
            .then(
              function (sessions) {
                course.sessions = sessions;
              },
              function (reason) {
                return serverErrorHandlerService.handleForbiddenError(reason);
              },
            )
            .then(function () {
              return getStat();
            });
        },
        getCompilationStructure: function () {
          course.compilationStructure = null;
          var reg = new LearningObjectRegistration({ id: course.selectedCourse.id });
          reg.init();
          return reg
            .initCompilationStructure($transition$.params().id)
            .then(
              function () {
                course.compilationStructure = reg.compilationStructure;
              },
              function (reason) {
                return serverErrorHandlerService.handleForbiddenError(reason);
              },
            )
            .then(function () {
              return getStat();
            });
        },
      };
    });
  }

  angular
    .module('kmi.lms.ubt.user.view', [
      'ui.router',
      'ui.bootstrap',
      'kmi.lms.core',
      'kmi.lms.components',
      'kmi.lms.course.registrations',
      'kmi.lms.course.common',
      'kmi.lms.user.certificates',
      'kmi.lms.course.purchase',
      'kmi.lms.user.certificates',
    ])
    .constant('registeredCoursesService', registeredCourses)
    .config(function ($stateProvider) {
      $stateProvider
        .state({
          name: 'main.user',
          url: '/user/{id}',
          component: 'ubtUserAccount',
          params: {
            id: null,
          },
          data: {
            label: 'User',
            availableFor: ['regularUser', 'admin'],
          },
          resolve: {
            account: function (User, $transition$) {
              return User.get($transition$.params().id);
            },
          },
        })
        .state({
          name: 'main.user.course',
          url: '/course',
          data: {
            label: 'Course',
            availableFor: ['regularUser', 'admin'],
          },
          abstract: true,
          template: '<ui-view/>',
          resolve: {
            registeredCourses: registeredCourses,
          },
        })
        .state({
          name: 'main.user.course.log',
          url: '/log',
          data: {
            label: 'Course',
            availableFor: ['regularUser', 'admin'],
          },
          component: 'ubtUserCourseLog',
        })
        .state({
          name: 'main.user.course.status',
          url: '/status',
          data: {
            label: 'Course',
            availableFor: ['regularUser', 'admin'],
          },
          component: 'ubtUserCourseStatus',
        })
        .state({
          name: 'main.user.course.history',
          url: '/history/{courseId}',
          data: {
            label: 'Course',
            availableFor: ['regularUser', 'admin'],
          },
          params: {
            courseId: null,
          },
          component: 'ubtCoursePurchaseManagement',
        })
        .state('edit.assign_course', {
          url: '/assign_course/{userId}',
          params: {
            userId: null,
          },
          component: 'ubtCourseAssignment',
          data: {
            label: 'Assign Course',
            availableFor: ['admin'],
            adminRoleLimits: [1, 18], //Course Registration Approval Manager
          },
          resolve: {
            account: function (User, $transition$) {
              return User.get($transition$.params().userId);
            },
          },
        });
    });
})();
