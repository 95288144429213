(function () {
  angular.module('kmi.lms.ubt.course').directive('launchCourse', launchCourse);

  /* @ngInject */
  function launchCourse(
    $uibModal,
    Course,
    serverErrorHandlerService,
    LearningObjectRegistration,
    notificationService,
    LearningObjectRegistrationWorkflow,
    moment,
  ) {
    return {
      restrict: 'E',
      scope: {
        course: '=',
        registrationUpdate: '&onUpdate',
      },
      template: '<a href="">{{::course.name}}</a>',
      link: postLink,
    };

    function postLink(scope, element, attrs) {
      var courseResult;
      function launchCourse() {
        /* @ngInject */
        function launchCourseModalController($scope, $uibModalInstance) {
          $scope.close = function () {
            $uibModalInstance.close();
          };
        }

        if (
          scope.registration.state === 'PreRequisitesCheck' ||
          (scope.registration.bls_prerequisites && scope.registration.bls_prerequisites.length > 0)
        ) {
          $uibModal.open({
            template: require('ajs/custom_modules/ubt/course/modal/alert-prerequisites.html').default,
            controller: launchCourseModalController,
            scope: scope,
          });
        } else if (scope.registration.state === 'AvailabilityCheck') {
          notificationService.error("You don't have access to this course", 5e3);
        } else if (scope.registration.state === 'ComponentRegistrationRestrictedCheck') {
          notificationService.error('Registration for the collection is required to access this component.', 5e3);
        } else if (scope.registration.state === 'CoursePublishedCheck') {
          notificationService.error(
            'Available for registration on ' + moment(scope.course.publishDate).format('ll'),
            5e3,
          );
        } else if (scope.registration.state === 'CourseRegOpenCheck') {
          notificationService.error(
            'Available for registration on ' + moment(scope.course.registrationOpensDate).format('ll'),
            5e3,
          );
        } else if (scope.registration.state === 'SeatsCheck') {
          notificationService.error('There are no seats available', 5e3);
        } else if (scope.registration.state === 'RegistrationDeadlineCheck') {
          notificationService.error(
            'Registration closed on ' + moment(scope.course.registrationDeadline).format('ll'),
            5e3,
          );
        } else {
          if (scope.workflow.hasAction('Launch')) {
            scope.workflow.exec('Launch', scope.$eval(attrs.actionData), { skipPrerequisites: true });
          } else {
            notificationService.error("You don't have access to this course", 5e3);
          }
        }
      }

      element.bind('click', function () {
        scope.$apply(function () {
          if (scope.registration) {
            launchCourse();
          } else {
            if (!courseResult) {
              courseResult = Course.get({ courseId: scope.course.id }).$promise.then(
                function (course) {
                  scope.course = course;

                  LearningObjectRegistration.get(scope.course).then(function (registration) {
                    scope.registration = registration;

                    scope.registration.on('updated', function () {
                      scope.registrationUpdate();
                    });

                    scope.workflow = new LearningObjectRegistrationWorkflow(scope.registration);

                    launchCourse();
                  });
                },
                function (reason) {
                  return serverErrorHandlerService.handleForbiddenError(reason);
                },
              );
            }
          }
        });
      });
    }
  }
})();
