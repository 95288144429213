(function () {
  angular.module('kmi.lms.ubt.user.edit').component('ubtCreateAccount', {
    template: require('ajs/custom_modules/ubt/user/edit/self-registration.html').default,
    controller: UbtCreateAccountCtrl,
    controllerAs: 'vm',
    bindings: {
      user: '<',
    },
  });

  function UbtCreateAccountCtrl($scope, elmsAuthService) {
    var vm = this;
    vm.$onInit = function () {
      $scope.user = vm.user;
    };

    $scope.createAccount = function () {
      $scope.$broadcast('event:user.save');
    };

    $scope.$on('event:user.saved', function (event, user) {
      $scope.cartState = 'confirm';

      return elmsAuthService.login({
        username: user.loginName,
        password: user.password,
      });
    });
  }
})();
