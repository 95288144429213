(function () {
  angular.module('kmi.lms.ubt.course').directive('ubtCompilationStructure', ubtCompilationStructure).run(moduleRun);

  function moduleRun($templateCache) {
    $templateCache.put(
      'custom_modules/ubt/course/compilation-structure-template.html',
      require('ajs/custom_modules/ubt/course/compilation-structure-template.html').default,
    );
  }

  function ubtCompilationStructure($compile, $http, $templateCache) {
    return {
      restrict: 'A',
      scope: {
        collection: '=',
        compilationRegistration: '=',
      },
      controller: UbtCompilationStructureController,
      controllerAs: 'vm',
      bindToController: true,
      compile: function (tElement) {
        return function (scope, element) {
          var templateLoader = $http
            .get('custom_modules/ubt/course/compilation-structure-template.html', { cache: $templateCache })
            .then(function (response) {
              tElement.html(response.data);
            });

          templateLoader.then(function () {
            element.html($compile(tElement.html())(scope));
          }, angular.noop);
        };
      },
    };
  }

  /* @ngInject */
  function UbtCompilationStructureController(courseRegistrationStatus, elms) {
    var vm = this;

    vm.getStatusName = getStatusName;
    vm.registrationUpdate = registrationUpdate;
    vm.formatDuration = formatDuration;

    function getStatusName(statusId, points, percentage) {
      var name = courseRegistrationStatus.getStatusName(statusId, 10);

      if ([5, 40, 41].indexOf(statusId) > -1) {
        if (points) {
          name = name + ' | ' + points.toFixed(2);
        }
        if (percentage) {
          name = name + ' | ' + percentage.toFixed(0) + '%';
        }
      }
      return name;
    }

    function registrationUpdate() {
      vm.compilationRegistration.reload();
    }

    function formatDuration(length) {
      return elms.formatDuration(length);
    }
  }
})();
