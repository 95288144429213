(function () {
  angular.module('kmi.lms.ubt.home', []).config(moduleConfig);

  function moduleConfig($stateProvider) {
    $stateProvider.state('main.home', {
      url: '/home',
      component: 'ubtHome',
      data: {
        label: 'Home',
        availableFor: ['anonymous'],
      },
    });
  }
})();
