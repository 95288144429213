(function () {
  angular.module('kmi.lms.ubt.user.view').component('ubtCoursePurchaseManagement', {
    template:
      require('ajs/custom_modules/ubt/user/view/components/course_purchase_management/course-purchase-management.html')
        .default,
    controller: UbtCoursePurchaseManagementCtrl,
    controllerAs: 'vm',
    bindings: {
      registeredCourses: '<',
      account: '<',
    },
  });

  /* @ngInject */
  function UbtCoursePurchaseManagementCtrl(
    $scope,
    $state,
    $uiRouterGlobals,
    $window,
    $uibModal,
    currentUser,
    serverErrorHandlerService,
    userCertificatesService,
    LearningObjectRegistration,
    notificationService,
    UserCourse,
    globalConfig,
    CoursePurchase,
    _,
  ) {
    var vm = this;

    vm.courseInfoIsLoading = true;
    vm.printReceipt = printReceipt;
    vm.modifyExpiration = modifyExpiration;
    vm.removeCourse = removeCourse;
    vm.printCertificate = printCertificate;
    vm.openAssignModal = openAssign;
    vm.adminView = globalConfig.settings.view === 'admin';

    vm.$onInit = activate;

    function activate() {
      if ($uiRouterGlobals.params.courseId) {
        var requestedCourse = _.find(vm.registeredCourses.courses, {
          id: parseInt($uiRouterGlobals.params.courseId, 10),
        });
        if (requestedCourse) {
          vm.registeredCourses.course.selectedCourse = requestedCourse;
        }
      }
    }

    $scope.$watch('vm.registeredCourses.course.selectedCourse', function () {
      if (vm.registeredCourses.course.selectedCourse) {
        bindCourseInfo();
      }
    });

    function bindCourseInfo() {
      vm.courseInfoIsLoading = true;
      LearningObjectRegistration.get(vm.registeredCourses.course.selectedCourse, vm.account.id).then(
        function (registration) {
          vm.registration = registration;

          angular.extend(vm.registeredCourses.course.selectedCourse, {
            accessExpirationDate: registration.accessExpirationDate,
          });

          vm.certificateAvailable =
            registration.certificates_data &&
            registration.certificates_data.print_status === 1 &&
            registration.certificates_data.certificates_list.length > 0 &&
            registration.certificate_issue_date &&
            registration.aggregated.electronicSignature &&
            (vm.account.id === currentUser.get().id || vm.account.checkPermission('courseregistration.view'));

          vm.courseInfoIsLoading = false;
        },
        function (reason) {
          vm.courseInfoIsLoading = false;
          return serverErrorHandlerService.handleForbiddenError(reason);
        },
      );

      bindPurchaseHistory();
    }

    function bindPurchaseHistory() {
      CoursePurchase.getHistory(vm.registeredCourses.course.selectedCourse.id, vm.account.id, true).then(
        function (response) {
          vm.purchaseHistory = response.data;
        },
      );
    }

    function showAccessDeniedMessage() {
      notificationService.error('You do not have permission for this operation', 2e3);
    }

    function openAssign() {
      if (vm.adminView && vm.account.checkPermission('courseRegistration.edit')) {
        $state.go('edit.assign_course', { userId: vm.account.id });
      } else {
        showAccessDeniedMessage();
      }
    }

    function printCertificate() {
      if (vm.account.id === currentUser.get().id || vm.account.checkPermission('courseregistration.view')) {
        if (vm.registration) {
          userCertificatesService.printCertificate(vm.registration.certificates_data.registration_id);
        }
      } else {
        showAccessDeniedMessage();
      }
    }

    function printReceipt() {
      $window.print();
    }

    function modifyExpiration() {
      if (
        vm.adminView &&
        vm.registeredCourses.course.selectedCourse &&
        vm.account.checkPermission('courseRegistration.edit')
      ) {
        $uibModal.open({
          component: 'ubtModifyCourseExpirationDateModal',
          resolve: {
            course: function () {
              return vm.registeredCourses.course.selectedCourse;
            },
            userId: function () {
              return vm.account.id;
            },
          },
        });
      } else {
        showAccessDeniedMessage();
      }
    }

    function removeCourse() {
      if (
        vm.adminView &&
        vm.account.checkPermission('courseRegistration.edit') &&
        (vm.registration.status_id || vm.registration.completedRegistration) &&
        vm.registeredCourses.course.selectedCourse
      ) {
        UserCourse.delete(
          { userId: vm.account.id, courseId: vm.registration.courseId },
          function () {
            bindPurchaseHistory();
          },
          function (reason) {
            return serverErrorHandlerService.handleForbiddenError(reason);
          },
        );
      } else {
        showAccessDeniedMessage();
      }
    }
  }
})();
