(function () {
  angular.module('kmi.lms.ubt.course').component('ubtRequirementModal', {
    template: require('ajs/custom_modules/ubt/course/modal/alert-requirements.html').default,
    controller: ubtRequirementModalCtrl,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  /* @ngInject */
  function ubtRequirementModalCtrl($scope) {
    var vm = this;

    vm.$onInit = function () {
      $scope.compilationStat = vm.resolve.compilationStat;
      $scope.course = vm.resolve.course;
    };

    $scope.close = function () {
      vm.modalInstance.close();
    };
  }
})();
