(function () {
  angular
    .module('kmi.lms.ubt.user.view')
    .component('ubtUserAccount', {
      template: require('ajs/custom_modules/ubt/user/view/user.html').default,
      controller: UbtUserAccountCtrl,
      controllerAs: 'vm',
      bindings: {
        account: '<',
      },
    })
    .run(moduleRun);

  function moduleRun($templateCache) {
    $templateCache.put(
      'custom_modules/ubt/user/view/components/user-info-control.html',
      require('ajs/custom_modules/ubt/user/view/components/user-info-control.html').default,
    );
  }

  function UbtUserAccountCtrl(
    $scope,
    $state,
    geoService,
    ubtUserGroupsService,
    serverErrorHandlerService,
    currentUser,
    notificationService,
    globalConfig,
  ) {
    var vm = this;

    vm.$onInit = activate;
    vm.isMainState = isMainState;
    vm.save = save;
    $scope.adminView = globalConfig.settings.view === 'admin';

    function activate() {
      $scope.account = vm.account;
      vm.currentUser = currentUser.get();

      if ($scope.account.country && $scope.account.stateId) {
        geoService
          .getState($scope.account.country, $scope.account.stateId)
          .toPromise()
          .then((state) => ($scope.state = state));
      }

      geoService
        .getTimeZone($scope.account.timeZoneId)
        .toPromise()
        .then((tz) => ($scope.timeZone = tz));

      ubtUserGroupsService.getUserCompanyData($scope.account, null, null).then(bindCompanyData, function (reason) {
        return serverErrorHandlerService.handleForbiddenError(reason);
      });

      $scope.toggleNotesEdit = function () {
        if ($scope.notesEditMode) {
          $scope.account
            .update({
              adminNotes: $scope.account.adminNotes,
            })
            .then(function () {
              $scope.notesEditMode = !$scope.notesEditMode;
            });
        } else {
          $scope.notesEditMode = !$scope.notesEditMode;
        }
      };
    }

    function isMainState() {
      return $state.is('main.user') || $state.is('main.userAdminDetails');
    }

    function bindCompanyData(data) {
      if (data) {
        $scope.manager = data.manager;
        $scope.company = data.company;
      }
    }

    function save() {
      return $scope.account
        .update({ roles: $scope.account.roles })
        .then(
          function () {
            notificationService.info('User roles updated successfully', 2e3);
          },
          function (reason) {
            serverErrorHandlerService.handleForbiddenError(reason);
          },
        )
        .finally(function () {});
    }
  }
})();
