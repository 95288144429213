// Module
var code = `<div> <div class="row standard-margin-bottom" ng-show="registeredCourses.courses && registeredCourses.courses.length"> <div class="col-sm-4"> <select class="form-control standard-margin-bottom" ng-model="registeredCourses.course.selectedCourse" ng-change="bindCourseData()" ng-options="c as c.name for c in registeredCourses.courses"></select> </div> <div class="col-sm-4"> <div class="alert alert-sm text-center" ng-class="{'alert-danger': registeredCourses.course.stat.spent < registeredCourses.course.stat.course_length,
                                                        'alert-success': registeredCourses.course.stat.spent >= registeredCourses.course.stat.course_length}"> Cumulative Time: {{formatDuration(registeredCourses.course.stat.spent) || '0h'}} </div> </div> <div class="col-sm-4" ng-show="registeredCourses.course.stat"> <div class="alert alert-sm text-center" ng-class="{'alert-danger':registeredCourses.course.stat.completed < registeredCourses.course.stat.total,
                                                        'alert-success': registeredCourses.course.stat.completed >= registeredCourses.course.stat.total}"> Required Modules: {{registeredCourses.course.stat.completed || 0}} of {{registeredCourses.course.stat.total || 0}} </div> </div> </div> <div class="alert alert-sm alert-info" ng-show="registeredCourses.course.sessions && registeredCourses.course.sessions.length === 0"> There are no records in the log. </div> <table class="table table-striped" ng-show="registeredCourses.course.sessions && registeredCourses.course.sessions.length"> <thead> <tr> <th class="col-sm-1 text-center hidden-xs">ID</th> <th class="col-sm-3 hidden-xs">Module</th> <th class="col-sm-2 hidden-xs">Session Start</th> <th class="col-sm-2 hidden-xs">Session End</th> <th class="col-sm-1 text-center hidden-xs">Session Total</th> <th class="col-sm-1 text-center hidden-xs">Cumulative</th> <th class="col-sm-2 text-center hidden-xs">Status</th> </tr> </thead> <tbody> <tr ng-repeat="session in registeredCourses.course.sessions"> <td colspan="6" class="visible-xs"> <div class="row"> <div class="col-xs-8 text-truncate"> {{session.courseName}} <small ng-if="session.courseNumber" class="text-muted"><em>{{session.courseNumber}}</em></small> </div> <div class="col-xs-4"> <div class="pull-right"> <span class="label label-wrap" ng-if="!session.completed" ng-class="{'label-warning': session.endReasonId === 2,
                                      'label-success': session.endReasonId === 1,
                                      'label-danger': session.endReasonId === 3,
                                      'label-info': session.endReasonId === 4  || (!session.endReasonId && session.endReason)
                                      }">{{session.endReason}}</span> <span class="label label-success" ng-if="session.completed">Completed</span> </div> </div> </div> <div class="row text-muted"> <div class="col-xs-6"> <small>{{formatDate(session.startTime)}}<br/><em>{{formatTime(session.startTime)}}</em></small> </div> <div class="col-xs-6 text-right"> <small>{{formatDuration(session.length) || '-'}}</small> </div> </div> </td> <td class="col-sm-1 text-center hidden-xs">{{session.courseNumber}}</td> <td class="col-sm-3 hidden-xs">{{session.courseName}}</td> <td class="col-sm-2 hidden-xs"> {{formatDate(session.startTime)}}<br/><em>{{formatTime(session.startTime)}}</em> </td> <td class="col-sm-2 hidden-xs"> {{formatDate(session.endTime)}}<br/><em>{{formatTime(session.endTime)}}</em> </td> <td class="col-sm-1 text-center hidden-xs">{{formatDuration(session.length) || '-'}}</td> <td class="col-sm-1 text-center hidden-xs">{{formatDuration(session.cumulativeLength) || '-'}}</td> <td class="col-sm-2 text-center hidden-xs"> <span class="label label-wrap" ng-if="!session.completed" ng-class="{'label-warning': session.endReasonId === 2,
                                      'label-success': session.endReasonId === 1,
                                      'label-danger': session.endReasonId === 3,
                                      'label-info': session.endReasonId === 4 || (!session.endReasonId && session.endReason)
                                      }">{{session.endReason}}</span> <span class="label label-success" ng-if="session.completed">Completed</span> </td> </tr> </tbody> </table> </div> `;
// Exports
export default code;