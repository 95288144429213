(function () {
  angular.module('kmi.lms.ubt.course').component('ubtConfirmSocialModal', {
    template: require('ajs/custom_modules/ubt/course/modal/confirm-social.html').default,
    controller: UbtConfirmSocialModalCtrl,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  function UbtConfirmSocialModalCtrl($scope, UserCourse, currentUser, LearningObjectRegistration) {
    var vm = this;
    vm.course = $scope.course;

    vm.$onInit = function () {
      vm.course = vm.resolve.course;
    };

    vm.close = function () {
      vm.modalInstance.dismiss('cancel');
    };

    vm.submitSignature = function () {
      vm.loading = true;

      UserCourse.update(
        {
          userId: currentUser.get().id,
          courseId: vm.course.id,
        },
        {
          signature: vm.full_name,
        },
        function () {
          LearningObjectRegistration.get(vm.course, null).then(function (response) {
            vm.loading = false;
            vm.modalInstance.close(response);
          });
        },
      );
    };
  }
})();
