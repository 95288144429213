(function () {
  angular.module('elmsSpaApp').component('ubtCatalog', {
    template: require('ajs/custom_modules/ubt/catalog/catalog.html').default,
    controller: UbtCatalogCtrl,
    controllerAs: 'vm',
    bindings: {
      availableCourses: '<',
      user: '<',
    },
  });

  function UbtCatalogCtrl($scope, $state, $uibModal) {
    var vm = this;
    vm.$onInit = onInit;
    vm.scope = $scope;

    function onInit() {
      if (vm.user.birthDate === null) {
        $uibModal.open({
          component: 'ubtRequiredUserDataComponent',
          windowClass: 'in',
          backdrop: 'static',
          keyboard: false,
        });
      }

      $scope.availableCoursesExists = vm.availableCourses && vm.availableCourses.length > 0;

      if (vm.availableCourses.length !== 3 && !$state.includes('main.catalog.list')) {
        $state.go('main.catalog.list');
      }
    }
  }
})();
