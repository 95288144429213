(function () {
  angular.module('kmi.lms.ubt.user.edit').factory('ubtUserGroupsService', function ($q, userGroupsService, _) {
    var managerGroupCategoryId = 600,
      companyGroupCategoryId = 500;

    return {
      getCompanies: getCompanies,
      getManagers: getManagers,
      getUserCompanyData: function (user, managers, companies) {
        var data = {
            company: null,
            manager: null,
          },
          def = $q.defer();

        if (!user || !user.groups || !user.groups.length) {
          def.reject();
        } else {
          $q.all([$q.when(companies || getCompanies()), $q.when(managers || getManagers(null))]).then(
            function (response) {
              var companies = response[0],
                managers = response[1];

              data.manager = getUserManager(user, managers);
              if (data.manager) {
                data.company = getManagerCompany(companies, data.manager);
              } else {
                data.company = getUserCompany(user, companies);
              }

              def.resolve(data);
            },
          );
        }

        return def.promise;
      },
    };

    function getCompanies() {
      return userGroupsService.getGroups({
        category: companyGroupCategoryId,
        selfReg: true,
      });
    }

    function getManagers(company) {
      var query = {
        category: managerGroupCategoryId,
        selfReg: true,
      };

      if (company) {
        query.parent = company;
      } else {
        company = '';
      }

      const managers = {};
      managers[company] = [];

      return userGroupsService.getGroups(query).then(function (groups) {
        _.forEach(groups, function (g) {
          managers[company].push(g);
        });

        return managers[company];
      });
    }

    function getUserCompany(user, companies) {
      var companyGroup = _.find(user.groups, { categoryId: companyGroupCategoryId });
      if (companyGroup) {
        return _.find(companies, { id: companyGroup.id });
      }

      return null;
    }

    function getUserManager(user, managers) {
      var managerGroup = _.find(user.groups, { categoryId: managerGroupCategoryId });

      if (managerGroup) {
        return _.find(managers, { id: managerGroup.id });
      }

      return null;
    }

    function getManagerCompany(companies, manager) {
      return _.find(companies, { id: manager.parentId });
    }
  });
})();
