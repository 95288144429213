(function () {
  angular.module('elmsSpaApp').config(function ($stateProvider) {
    $stateProvider
      .state('main.catalog', {
        abstract: true,
        url: '/catalog',
        component: 'ubtCatalog',
        data: {
          label: 'CATALOG',
          availableFor: ['regularUser', 'admin'],
        },
        resolve: {
          user: function (User) {
            return User.get();
          },
          userCourses: function (UserCourse, currentUser, courseRegistrationStatus, _) {
            return UserCourse.query({
              userId: currentUser.get().id,
              query: angular.toJson({ format_type_id: 4 }),
            }).$promise.then(function (response) {
              return _.filter(response.courses, (item) =>
                _.union(courseRegistrationStatus.inProgressSet, courseRegistrationStatus.completedSet, [
                  courseRegistrationStatus.approved,
                ]).includes(item.statusId),
              );
            });
          },
          availableCourses: function (userCoursesService) {
            return userCoursesService
              .getAvailableCourses({
                courseFormatTypeId: 4,
                nonFree: true,
                untouched: true,
              })
              .then(function (response) {
                return response.data.availableCourses;
              });
          },
        },
      })
      .state('main.catalog.tile', {
        url: '',
        data: {
          label: 'Catalog',
          availableFor: ['regularUser', 'admin'],
        },
        views: {
          myCoursesView: {
            component: 'ubtCatalogMyCourses',
          },
          availableCoursesView: {
            component: 'ubtCatalogAvailableCoursesTile',
          },
        },
      })
      .state('main.catalog.list', {
        url: '/list',
        data: {
          label: 'Catalog',
          availableFor: ['regularUser', 'admin'],
        },
        views: {
          myCoursesView: {
            component: 'ubtCatalogMyCourses',
          },
          availableCoursesView: {
            component: 'ubtCatalogAvailableCoursesList',
          },
        },
      });
  });
})();
