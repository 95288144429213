(function () {
  angular
    .module('elmsSpaApp')
    .component('ubtCatalogAvailableCoursesTile', {
      template: require('ajs/custom_modules/ubt/catalog/available.courses.tile.html').default,
      controller: UbtCatalogAvailableCoursesCtrl,
      controllerAs: 'vm',
      bindings: {
        availableCourses: '<',
      },
    })
    .component('ubtCatalogAvailableCoursesList', {
      template: require('ajs/custom_modules/ubt/catalog/available.courses.list.html').default,
      controller: UbtCatalogAvailableCoursesCtrl,
      controllerAs: 'vm',
      bindings: {
        availableCourses: '<',
      },
    });

  function UbtCatalogAvailableCoursesCtrl($scope) {
    var vm = this;
    vm.$onInit = function () {
      $scope.model = {
        availableCourses: vm.availableCourses,
      };
    };
  }
})();
