(function () {
  angular.module('kmi.lms.ubt.user.edit').directive('editUserForm', function () {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        user: '=user',
        selfEdit: '=selfEdit',
        formPending: '=?',
      },
      template: require('ajs/custom_modules/ubt/user/edit/components/edit-control.html').default,
      controller: UbtEditUserCtrl,
    };
  });

  /* @ngInject */
  function UbtEditUserCtrl(
    $scope,
    notificationService,
    $q,
    $interval,
    geoService,
    ubtUserGroupsService,
    userPasswordService,
    _,
    regexEmailPattern,
    $http,
  ) {
    var defaultZone,
      defaultCountry = 'US';

    $scope.submitted = false;
    $scope.formPending = null;
    $scope.taxIdPattern = /^\d{9}$/;
    $scope.addressPattern = /^((?!(po|p.o.*)\s*\W*box).)*$/i;

    $scope.emailPattern = regexEmailPattern;

    $scope.groups = {
      company: null,
      manager: null,
    };

    function bindStates(states) {
      $scope.states = states;
    }

    function bindCompanyData(data) {
      if (data) {
        $scope.groups.manager = data.manager;
        $scope.groups.company = data.company || null;
        filterManagers();
      }
    }

    function bindTimeZones(timeZones) {
      $scope.timeZones = timeZones;
      defaultZone = _.find(timeZones, { default: true });
    }

    function bindManagers(groups) {
      $scope.managers = groups;
    }

    function bindCompanies(groups) {
      $scope.companies = groups;
    }

    function initDefaultUserData() {
      $scope.emailConfirmation = $scope.user.email;
      $scope.passwordConfirmation = $scope.user.password;

      if (!$scope.user.taxIdProvided) {
        $scope.editTaxId = true;
      }
    }

    //actions
    $scope.resetPassword = function () {
      if ($scope.selfEdit) {
        userPasswordService.changePasswordDialog($scope.user);
      } else {
        userPasswordService.resetPasswordDialog($scope.user);
      }
    };

    $scope.providePassword = function () {
      userPasswordService.providePasswordDialog($scope.user);
    };

    $scope.saveTaxId = function () {
      if ($scope.taxIdForm.$valid && $scope.taxId) {
        $scope.user.taxId = $scope.taxId;
        $scope.cancelTaxIdChanges();
      }
    };

    $scope.cancelTaxIdChanges = function () {
      $scope.editTaxId = false;
      $scope.taxId = null;
      $scope.taxIdConfirmation = null;
    };

    //Event Handlers
    $scope.companyChange = function () {
      $scope.groups.manager = null;

      if ($scope.groups.company && $scope.groups.company.name.toLowerCase() === 'other') {
        $scope.filteredManagers = [];
      } else {
        filterManagers();
      }

      $scope.groupsChange();
    };

    function filterManagers() {
      if ($scope.groups.company) {
        $scope.filteredManagers = _.filter($scope.managers, { parentId: $scope.groups.company.id || -1 });
        if ($scope.filteredManagers && $scope.filteredManagers.length === 1) {
          $scope.groups.manager = $scope.filteredManagers[0];
        }
      } else {
        $scope.filteredManagers = [];
      }
    }

    $scope.groupsChange = function () {
      $scope.user.setGroups([$scope.groups.manager ? $scope.groups.manager : $scope.groups.company]);
    };

    $scope.emailChange = function () {
      $scope.user.loginName = $scope.user.email;
    };

    $scope.submitForm = function () {
      $scope.submitted = true;
      $scope.$emit('event:validation.pending', true);

      let validationPending = $interval(function () {
        if (!$scope.userForm.$pending) {
          if (!$http.pendingRequests.length) {
            $interval.cancel(validationPending);

            if ($scope.userForm.$valid) {
              $scope.formPending = $scope.user
                .save()
                .then(
                  function () {
                    $scope.$emit('event:user.saved', $scope.user);
                  },
                  function () {
                    notificationService.error('An error occurred saving user data. Please try again later.', 5e3);
                    $scope.$emit('event:validation.pending', false);
                  },
                )
                .finally(function () {
                  $scope.formPending = null;
                });
            } else {
              notificationService.error('Please fill in all required fields', 2e3);
              $scope.$emit('event:validation.pending', false);
            }
          }
        }
      }, 100);
    };

    //initialization
    initDefaultUserData();

    $q.all([
      geoService.getStates(defaultCountry).toPromise().then(bindStates),
      geoService.getTimeZones().toPromise().then(bindTimeZones),
      ubtUserGroupsService.getCompanies().then(bindCompanies),
      ubtUserGroupsService.getManagers().then(bindManagers),
    ])
      .then(function initNewUserDefaultValues() {
        if (!$scope.user.id) {
          if (defaultZone) {
            $scope.user.timeZoneId = defaultZone.id;
          } else {
            $scope.user.timeZoneId = 86; //(GMT-08:00) Pacific Time (US & Canada)
          }

          var state = _.find($scope.states, { abv: 'CA' });
          if (state) {
            $scope.user.stateId = state.id;
          }

          $scope.user.country = defaultCountry;
        }
      })
      .then(function initCompanyData() {
        ubtUserGroupsService.getUserCompanyData($scope.user, $scope.managers, $scope.companies).then(bindCompanyData);
      });
  }
})();
