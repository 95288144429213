import {
  configureApplicationRuntimeSettings,
  configureApplicationSettings,
} from '../../modules/app/configuration/application-configuration';

require('ajs/vendors');
require('ajs/modules/app/models/class.ts');
require('ajs/global-dependencies.ts');
require('ajs/global-scope.ts');
require('ajs/modules/app/_init.ts');

declare const angular: angular.IAngularStatic;

angular
  .module('elmsSpaApp', [
    'ui.router',
    'ui.router.upgrade',
    'http-auth-interceptor',
    'ui.bootstrap',
    'angular-ladda',
    'ngCookies',
    'ngAnimate',
    'ngResource',
    'ui.mask',
    'ngSanitize',
    'ui.tree',
    'globalDependencies',
    'kmi.lms.services',
    'ngAria',
    'kmi.lms.core',
    'kmi.lms.core.login',
    'kmi.lms.components',
    'kmi.lms.ubt.home',
    'kmi.lms.dictionary',
    'kmi.lms.course.common', // TODO VK dependency needs to be reviewed
    'kmi.lms.user.common',
    'kmi.lms.user.learning',
    'kmi.lms.search',
    'kmi.lms.course.registrations',
    'blueimp.fileupload',
    'kmi.lms.ubt',
    'kmi.lms.passwords',
    'kmi.lms.ubt.user.edit',
    'kmi.lms.ubt.user.view',
  ])
  .config(configureApplicationSettings)
  .run(configureApplicationRuntimeSettings);

require('ajs/core-modules');

require('ajs/custom_modules/ubt/user/edit/_init.ts');
require('ajs/custom_modules/ubt/user/edit/admin-user-component.ts');
require('ajs/custom_modules/ubt/user/edit/components/edit-user-control-directive.ts');
require('ajs/custom_modules/ubt/user/edit/create-account-component.ts');
require('ajs/custom_modules/ubt/user/edit/edit-account-component.ts');
require('ajs/custom_modules/ubt/user/edit/components/required-user-data-component.ts');
require('ajs/custom_modules/ubt/user/edit/user-groups-service.ts');
require('ajs/custom_modules/ubt/user/view/_init.ts');
require('ajs/custom_modules/ubt/user/view/components/course_log/course-log-component.ts');
require('ajs/custom_modules/ubt/user/view/components/course_purchase_management/course-purchase-management-component.ts');
require('ajs/custom_modules/ubt/user/view/components/course_status/course-status-component.ts');
require('ajs/custom_modules/ubt/user/view/user-account-component.ts');

require('ajs/custom_modules/ubt/catalog/_init.ts');
require('ajs/custom_modules/ubt/catalog/available-courses-component.ts');
require('ajs/custom_modules/ubt/catalog/catalog-component.ts');
require('ajs/custom_modules/ubt/catalog/my-courses-component.ts');
require('ajs/custom_modules/ubt/course/_init.ts');
require('ajs/custom_modules/ubt/course/compilation-course-filter.ts');
require('ajs/custom_modules/ubt/course/course-details-config.ts');
require('ajs/custom_modules/ubt/course/compilation-structure-directive.ts');
require('ajs/custom_modules/ubt/course/modal/alert-requirements-component.ts');
require('ajs/custom_modules/ubt/course/modal/confirm-social-modal-component.ts');
require('ajs/custom_modules/ubt/course/course-details-component.ts');
require('ajs/custom_modules/ubt/course/launch-course-directive.ts');
require('ajs/custom_modules/ubt/home/_init.ts');
require('ajs/custom_modules/ubt/home/home-component.ts');
require('ajs/modules/course/purchase/_init.ts');
require('ajs/modules/course/purchase/history/course-purchase-history-directive.ts');
require('ajs/modules/course/purchase/history/course-receipt-component.ts');
require('ajs/modules/course/purchase/wizard/user-account/create-account-component.ts');
require('ajs/modules/course/purchase/wizard/payment/payment-component.ts');
require('ajs/modules/course/purchase/wizard/payment/modal/terms-of-use-component.ts');
require('ajs/modules/course/purchase/wizard/payment/modal/csc-code-modal-component.ts');
require('ajs/modules/course/purchase/wizard/payment/modal/purchase-confirmation-modal-component.ts');
require('ajs/modules/course/purchase/wizard/purchase-component.ts');
require('ajs/modules/course/purchase/wizard/purchase-wizard.ts');
require('ajs/modules/course/purchase/wizard/purchase-service.ts');
require('ajs/modules/course/purchase/wizard/result/result-component.ts');
require('ajs/modules/course/purchase/wizard/cart/cart-view-directive.ts');
require('ajs/modules/course/purchase/wizard/cart/cart-component.ts');

require('ajs/custom_modules/ubt/_init.ts');
require('ajs/custom_modules/ubt/app/navigation-component.ts');
require('ajs/custom_modules/ubt/contact/contact-directive.ts');
require('ajs/custom_modules/ubt/about/about-component.ts');
require('ajs/custom_modules/ubt/search/_init.ts');

require('src/projects/ubt/index.module.ts');
