(function () {
  angular.module('elmsSpaApp').component('ubtCatalogMyCourses', {
    template: require('ajs/custom_modules/ubt/catalog/my.courses.html').default,
    controller: UbtCatalogMyCoursesCtrl,
    controllerAs: 'vm',
    bindings: {
      userCourses: '<',
    },
  });

  function UbtCatalogMyCoursesCtrl($scope, $timeout, moment, _) {
    var vm = this,
      delay = 1000;

    vm.$onInit = function () {
      $scope.model = {
        userCourses: vm.userCourses,
      };

      onTimeout();
    };

    function onTimeout() {
      angular.forEach($scope.model.userCourses, function (userCourse) {
        if (userCourse.accessExpirationDate) {
          userCourse.leftUntilExpired = moment(userCourse.accessExpirationDate).fromNow();
          userCourse.isExpired = moment(userCourse.accessExpirationDate).diff(moment(), 'seconds') <= 0;
        }

        if (_.get(userCourse, 'course.price') && userCourse.course.price.type_id === 2) {
          userCourse.extensionPrice = userCourse.course.price.amount;
        }
      });
      $timeout(onTimeout, delay);
    }
  }
})();
