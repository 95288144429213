(function () {
  angular.module('kmi.lms.ubt.course').filter('ubtExtractCompilationCourse', function () {
    return function (item) {
      if (item) {
        if (item.type === 1) {
          return [item];
        } else if (item.type === 2) {
          return item.items;
        }
      }

      return [];
    };
  });
})();
