(function () {
  angular.module('kmi.lms.ubt.course').config(function ($stateProvider) {
    $stateProvider.state('main.course.default', {
      url: '/',
      data: {
        label: 'Course',
        availableFor: ['regularUser', 'admin'],
      },
      component: 'ubtCourseDetails',
    });
  });
})();
