(function () {
  angular.module('kmi.lms.core').config(coreModuleConfig);

  function coreModuleConfig($stateProvider) {
    $stateProvider
      .state('main', {
        url: '/u',
        template: require('ajs/custom_modules/ubt/app/base-view.html').default,
        abstract: true,
      })
      .state('mainPrint', {
        url: '/p',
        template: require('ajs/custom_modules/ubt/app/base-view-print.html').default,
        abstract: true,
      })
      .state('prompt', {
        url: '/prompt',
        component: 'promptView',
        abstract: true,
      });
  }

  angular.module('kmi.lms.ubt', ['kmi.lms.ubt.course']);

  angular.module('elmsSpaApp').config(moduleConfig).run(moduleRun);

  function moduleConfig($stateProvider) {
    $stateProvider.state('main.help', {
      url: '/help',
      data: {
        label: 'Help',
        availableFor: ['anonymous', 'regularUser', 'admin'],
      },
      onEnter: function ($window) {
        $window.location.href = 'https://upwardbound.helpsite.com/';
      },
    });
  }

  function moduleRun($state, $templateCache, globalConfig) {
    $templateCache.put(
      'custom_modules/ubt/home/property_and_casualty.html',
      require('ajs/custom_modules/ubt/home/property_and_casualty.html').default,
    );

    $templateCache.put(
      'custom_modules/ubt/home/life_and_health.html',
      require('ajs/custom_modules/ubt/home/life_and_health.html').default,
    );

    $templateCache.put(
      'custom_modules/ubt/course/certificate.html',
      require('ajs/custom_modules/ubt/course/certificate.html').default,
    );

    let searchState = $state.get('main.search');
    if (searchState) {
      if (!searchState.data) {
        searchState.data = {};
      }

      searchState.data.availableFor = ['admin'];
      searchState.data.adminRoleLimits = [1, 2, 3, 15, 50, 90, 105, 110];
    }

    let courseState = $state.get('main.course');
    if (courseState && globalConfig.settings.view === 'admin') {
      if (!courseState.data) {
        courseState.data = {};
      }

      courseState.data.availableFor = ['admin'];
      courseState.data.permittedFor = ['course.view'];
    }
  }
})();
