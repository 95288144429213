(function () {
  angular.module('kmi.lms.ubt.user.view').component('ubtUserCourseLog', {
    template: require('ajs/custom_modules/ubt/user/view/components/course_log/course-log.html').default,
    controller: UbtUserCourseLogCtrl,
    controllerAs: 'vm',
    bindings: {
      registeredCourses: '<',
    },
  });

  function UbtUserCourseLogCtrl($scope, elms) {
    var vm = this;
    vm.$onInit = function () {
      $scope.registeredCourses = vm.registeredCourses;
    };

    $scope.formatDate = function (date) {
      return elms.formatDate(date);
    };

    $scope.formatTime = function (date) {
      return elms.formatTime(date);
    };

    $scope.formatDuration = function (length) {
      return elms.formatDuration(length);
    };

    $scope.$watch('registeredCourses.course.selectedCourse', function () {
      if (vm.registeredCourses.course.selectedCourse) {
        vm.registeredCourses.getSessions();
      }
    });
  }
})();
