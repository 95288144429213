(function () {
  angular.module('kmi.lms.ubt.course').component('ubtCourseDetails', {
    template: require('ajs/custom_modules/ubt/course/course.html').default,
    controller: UbtCourseDetailsCtrl,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function UbtCourseDetailsCtrl(
    $scope,
    $state,
    $uiRouterGlobals,
    $uibModal,
    LearningObjectRegistration,
    elmsAuthService,
    Course,
    userCertificatesService,
    elms,
    moment,
    LearningObjectRegistrationWorkflow,
    serverErrorHandlerService,
    courseComponentsService,
    _,
    backUrlService,
  ) {
    $scope.ready = false;
    $scope.backService = backUrlService;

    activate();

    function activate() {
      Course.get({ courseId: $uiRouterGlobals.params.id, cacheByPass: true }, bindCourse, handleServerError);
      $scope.$on('event:course.registration.action.finished', refreshUserAuthInfo);
      $scope.$watchCollection('registration.compilationStructure', initCompilationStat, true);

      function refreshUserAuthInfo() {
        elmsAuthService.refreshUserLoggedIn().then(function (result) {
          if (!result) {
            $state.go('prompt.login');
          }
        });
      }

      function bindCourse(course) {
        $scope.course = course;
        bindCourseRegistration();
      }

      function bindCourseRegistration() {
        return LearningObjectRegistration.get($scope.course, null)
          .then(function (registration) {
            $scope.registration = registration;
            $scope.workflow = new LearningObjectRegistrationWorkflow($scope.registration);
          }, handleServerError)
          .then(function () {
            return courseComponentsService
              .getCompilationStructure($scope.course.id, $scope.registration.userId)
              .then(function (response) {
                $scope.registration.compilationStructure = response.structure;
                initCompilationStat();
              });
          })
          .finally(function () {
            $scope.ready = true;
          });
      }

      $scope.$on('event:course.registration.updated', function () {
        if ($scope.course && $scope.registration) {
          courseComponentsService
            .getCompilationStructure($scope.course.id, $scope.registration.userId)
            .then(function (response) {
              $scope.registration.compilationStructure = response.structure;
              initCompilationStat();
            });
        }
      });

      function handleServerError(reason) {
        return serverErrorHandlerService.handleForbiddenError(reason, function (data) {
          $scope.error = data;
        });
      }
    }

    $scope.formatDuration = function (length) {
      return elms.formatDuration(length);
    };

    $scope.expirationText = function () {
      if (!$scope.registration.aggregated || $scope.registration.aggregated.accessExpirationDate === null) {
        $scope.course.isExpired = false;
        return '';
      }
      var expDate = moment($scope.registration.aggregated.accessExpirationDate);
      $scope.course.isExpired = expDate.diff(moment(), 'seconds') <= 0;
      return $scope.course.isExpired ? '(Expired)' : '(Expires ' + expDate.fromNow() + ')';
    };

    $scope.printCertificate = function () {
      if (!$scope.registration) {
        return;
      }

      var issueDateEmpty = $scope.registration.certificate_issue_date === null,
        compilationUncomplete = $scope.compilationStat.required_completions < $scope.compilationStat.required_total,
        courseUncomplte = $scope.compilationStat.total_spent < $scope.course.length * 3600;

      if (issueDateEmpty && (compilationUncomplete || courseUncomplte)) {
        $uibModal.open({
          component: 'ubtRequirementModal',
          resolve: {
            compilationStat: $scope.compilationStat,
            course: $scope.course,
          },
        });
      } else {
        if (!$scope.registration.aggregated.electronicSignature) {
          var modalInstance = $uibModal.open({
            component: 'ubtConfirmSocialModal',
            resolve: {
              course: function () {
                return $scope.course;
              },
            },
          });

          modalInstance.result.then(function (registration) {
            if (registration.certificates_data) {
              userCertificatesService.printCertificate(registration.certificates_data.registration_id);
            }
            angular.extend($scope.registration, registration);
          });
        } else {
          if (_.get($scope, 'registration.certificates_data.registration_id')) {
            userCertificatesService.printCertificate($scope.registration.certificates_data.registration_id);
          }
        }
      }
    };

    $scope.printReceipt = function () {
      $state.go('mainPrint.coursePurchaseReceipt', { courseId: $scope.course.id, userId: $scope.registration.userId });
    };

    function aggregateRegistrationsInfo() {
      function countNodes(node) {
        var totalSpent = 0;
        var requiredCompletions = 0;
        var requiredTotal = 0;

        _.forEach(node.items, function (blsItem) {
          if (blsItem.type === 2) {
            var countItems = countNodes(blsItem);
            totalSpent += countItems.total_spent;
            requiredCompletions += countItems.required_completions;
            requiredTotal += countItems.required_total;
          } else {
            if (blsItem.spent) {
              totalSpent += blsItem.spent;
            }

            if (node.required) {
              requiredTotal++;
              if (_.includes([5, 40], blsItem.status_id)) {
                requiredCompletions++;
              }
            }
          }
        });

        return {
          total_spent: totalSpent,
          required_completions: requiredCompletions,
          required_total: requiredTotal,
        };
      }

      var countItems = countNodes({ items: $scope.registration.compilationStructure, type: 2, required: true });

      return {
        total_spent: countItems.total_spent,
        required_completions: countItems.required_completions,
        required_total: countItems.required_total,
      };
    }

    function initCompilationStat() {
      if ($scope.registration && $scope.registration.compilationStructure) {
        $scope.compilationStat = aggregateRegistrationsInfo();
      }
    }

    $scope.maxLength = function (values) {
      return Math.max.apply(null, values);
    };
  }
})();
