(function () {
  angular.module('elmsSpaApp').component('ubtNavigationComponent', {
    template: require('ajs/custom_modules/ubt/app/navigation.html').default,
    controller: NavigationController,
    controllerAs: 'vm',
    bindings: {
      nonInteractive: '<?',
    },
  });

  /* @ngInject */
  function NavigationController(
    $scope,
    $window,
    rootScopeService,
    $state,
    currentUser,
    menuService,
    globalConfig,
    siteSettingService,
    securityService,
    drawerService,
  ) {
    var vm = this;

    vm.state = $state;
    vm.allTabs = []; //default blank tabs list

    vm.isStateAvailable = securityService.isStateAvailable;
    vm.appSettings = globalConfig.settings;
    vm.userSettings = currentUser.get();
    vm.siteSetting = siteSettingService.getSiteSetting();

    vm.login = login;
    vm.drawerService = drawerService;

    vm.title = title;
    vm.$onInit = activate;
    vm.print = print;

    function activate() {
      // initializes menu on start of the application
      initUserEnvironment();

      function initUserEnvironment() {
        vm.allTabs = $scope.allTabs = menuService.getMenuItems();
      }

      $scope.$on('elmsAuthService.notification.login', function () {
        vm.userSettings = currentUser.get();
      });
    }

    function title() {
      var menuItems = menuService.getMenuItems();

      for (var i = 0; i < menuItems.length; i++) {
        var stateItem = menuItems[i];
        if (stateItem.state && $state.is(stateItem.state)) {
          return stateItem.title;
        }
      }

      return angular.isDefined($state.current.data) && angular.isDefined($state.current.data.label)
        ? $state.current.data.label
        : '';
    }

    function login() {
      rootScopeService.broadcast('event:auth-loginRequired');
    }

    function print() {
      $window.print();
    }
  }
})();
