(function () {
  angular.module('kmi.lms.ubt.user.edit').component('ubtEditAccount', {
    template: require('ajs/custom_modules/ubt/user/edit/account.html').default,
    controller: UbtEditAccountCtrl,
    controllerAs: 'vm',
    bindings: {
      user: '<',
    },
  });

  function UbtEditAccountCtrl($scope, backUrlService) {
    var vm = this;
    vm.$onInit = function () {
      $scope.user = vm.user;
    };

    $scope.saveAccount = function () {
      $scope.$broadcast('event:user.save');
    };

    $scope.$on('event:user.saved', function () {
      backUrlService.goBack();
    });
  }
})();
