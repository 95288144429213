(function () {
  angular.module('kmi.lms.ubt.user.view').component('ubtUserCourseStatus', {
    template: require('ajs/custom_modules/ubt/user/view/components/course_status/course-status.html').default,
    controller: UbtUserCourseStatusCtrl,
    controllerAs: 'vm',
    bindings: {
      registeredCourses: '<',
    },
  });

  function UbtUserCourseStatusCtrl($scope, courseRegistrationStatus, elms) {
    var vm = this;

    $scope.formatDate = function (date) {
      return elms.formatDate(date);
    };

    $scope.formatTime = function (date) {
      return elms.formatTime(date);
    };

    $scope.formatDuration = function (length) {
      return elms.formatDuration(length);
    };

    $scope.getStatusName = function (statusId, points, percentage) {
      var name = courseRegistrationStatus.getStatusName(statusId, 10);
      if ([5, 40, 41].indexOf(statusId) > -1) {
        if (points !== undefined && points !== null && points >= 0) {
          name = name + ' | ' + points.toFixed(2);
        }
        if (percentage !== undefined && percentage !== null && percentage >= 0) {
          name = name + ' | ' + percentage.toFixed(0) + '%';
        }
      }
      return name;
    };

    vm.$onInit = function () {
      $scope.registeredCourses = vm.registeredCourses;
    };

    $scope.$watch('registeredCourses.course.selectedCourse', function () {
      if (vm.registeredCourses.course.selectedCourse) {
        vm.registeredCourses.getCompilationStructure();
      }
    });
  }
})();
